<template>
    <div class="root">
        <div style="border: 1px solid #cccccc; padding: 10px;">
            <el-form :model="company" label-width="120px">
                <el-form-item :label="$t('company.setting_company_name')">
                    <el-input v-model="company.companyName" maxlength="50" />
                </el-form-item>
                <el-form-item label="EMAIL">
                    <el-input v-model="company.companyEmail" :readonly="readonly">
                        <template #append>
                            <el-button v-if="readonly === true" plain type="primary" icon="EditPen"
                                @click="editEmailHandler()" />
                            <el-button v-if="readonly === false" plain type="primary"
                                @click="cannelEmailHandler()">取消</el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item :label="$t('company.setting_area')">
                    <el-cascader v-model="company.companyArea" :options="options" @change="handleChange">
                    </el-cascader>
                </el-form-item>
                <el-form-item :label="$t('company.setting_location')">
                    <el-input v-model="company.companyLocation" maxlength="100" />
                </el-form-item>
                <el-form-item :label="$t('company.setting_phone')">
                    <el-input v-model="company.companyPhone" maxlength="11" />
                </el-form-item>
                <el-form-item label="LOGO">
                    <el-upload class="avatar-uploader" :auto-upload="true" list-type="picture-card"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="company.companyLogo" :src="company.companyLogo" class="avatar" />
                        <el-icon v-else class="avatar-uploader-icon">
                            <Plus />
                        </el-icon>
                        <el-dialog v-model="dialogVisible">
                            <img w-full style="width: 100%;" :src="dialogImageUrl" alt="Preview Image" />
                        </el-dialog>
                    </el-upload>
                </el-form-item>
                <el-form-item :label="$t('company.setting_code')">
                    <div v-highlight style="text-align: left;">
                        <code class="lang-javascript">
                            {{
                                "<script src = 'https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js' />"
                            }}
                        </code>
                        <br />
                        <code class="lang-javascript">
                            {{
                                "<script src = 'https://www.booking1s.com/qr.js?id="+company.companyIdnum+"&top=13&right=100' />"
                            }}
                        </code>
                    </div>
                </el-form-item>
                <div style="height: 40px; padding-top: 15px; text-align: right;">
                    <span class="dialog-footer">
                        <!-- <el-button @click="dialogFormVisible = false">Cancel</el-button> -->
                        <el-button type="primary" @click="saveCompanySettingHandler">Confirm</el-button>
                    </span>
                </div>
            </el-form>
        </div>
        <div style="height: 10px"></div>
        <div style="width: 100%; display: flex;">
            <div style="border: 1px solid #cccccc; padding: 10px; width: 48%; padding: 10px;">
                <div style="height: 40px; width: 100%; text-align: left; size: 17px; font-weight: bold;">
                    {{ $t('company.setting_service') }}</div>
                <el-row>
                    <el-col :span="18">
                        <el-form-item :label="$t('company.setting_service_name')">
                            <el-input v-model="project.projectName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="5" style="text-align: right;">
                        <el-button type="primary" @click="addProjectHandler">{{ $t('company.setting_service_add')
                            }}</el-button>
                    </el-col>
                </el-row>
                <div>
                    <div style="width: 100%; text-align: left">{{ $t('company.setting_service_list') }}</div>
                    <el-scrollbar height="200px" style="width: 100%">
                        <!-- <div v-for="service in services" :key="service.id"> -->
                        <el-checkbox v-for="service in services" :key="service.id" :checked="service.checked"
                            :label="service.projectName" size="large" border style="width: 100%; margin-top: 10px;">
                            <div style="display: flex; justify-content: space-between; width: 100%; height: 40px; ">
                                <div style="line-height: 40px;">{{ service.projectName }}</div>
                                <div style="width: 100%;"></div>
                                <el-button type="primary" icon="Delete" style="color: red; margin-top: 7px;" plain
                                    size="small" @click="delProjectHandler(service.projectId)" />
                            </div>
                        </el-checkbox>
                        <!-- </div> -->
                    </el-scrollbar>
                </div>
            </div>
            <div style="width: 5px"></div>
            <div style="border: 1px solid #cccccc; padding: 10px; width: 48%; padding: 10px;">
                <div style="height: 40px; width: 100%; text-align: left; size: 17px; font-weight: bold;">
                    {{ $t('company.setting_booking_title') }}</div>
                <el-form-item :label="$t('company.setting_booking_date')">
                    <el-checkbox v-for="(week, index) in weeks" :key="week.name" :checked="week.checked"
                        v-model="week.checked" :label="week.name" @change="changeHandler(index)" border
                        style="margin-top: 10px" />
                </el-form-item>
                <el-form-item :label="$t('company.setting_booking_time')">
                    <el-row>
                        <el-col :span="10">
                            <el-time-select v-model="company.startTime" :max-time="timeRange.endTime"
                                placeholder="Start time" start="08:30" step="00:15" end="23:30" style="width:160px" />
                        </el-col>
                        <el-col :span="4"></el-col>
                        <el-col :span="10">
                            <el-time-select v-model="company.endTime" :min-time="timeRange.startTime"
                                placeholder="End time" start="08:30" step="00:15" end="23:30" style="width:160px" />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item :label="$t('company.setting_booking_unit')">
                    <el-select v-model="company.itmeUnits" style="width:100%" placeholder="Select">
                        <el-option v-for="item in itmeUnits" :key="item.label" :label="item.label"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <div style="width: 100%; text-align: right;">
                    <el-button type="primary" @click="saveWeekHandler">Confirm</el-button>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import utils from '../utils/Utils'
import CryptoJS from 'crypto-js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useI18n } from 'vue-i18n'


export default {
    data() {
        return {
            company: {},
            project: {},
            services: [],
            weeks: [
                { name: '周日', checked: false, week: 0, weekState: 0, companyId: JSON.parse(localStorage.getItem('company')).companyId },
                { name: '周一', checked: false, week: 1, weekState: 0, companyId: JSON.parse(localStorage.getItem('company')).companyId },
                { name: '周二', checked: false, week: 2, weekState: 0, companyId: JSON.parse(localStorage.getItem('company')).companyId },
                { name: '周三', checked: false, week: 3, weekState: 0, companyId: JSON.parse(localStorage.getItem('company')).companyId },
                { name: '周四', checked: false, week: 4, weekState: 0, companyId: JSON.parse(localStorage.getItem('company')).companyId },
                { name: '周五', checked: false, week: 5, weekState: 0, companyId: JSON.parse(localStorage.getItem('company')).companyId },
                { name: '周六', checked: false, week: 6, weekState: 0, companyId: JSON.parse(localStorage.getItem('company')).companyId }],
            timeRange: { startTime: '', endTime: '' },
            itmeUnits: [{ label: '30分钟', value: 30 }, { label: '60分钟', value: 60 }],
            value: '',
            dialogImageUrl: '',
            dialogVisible: false,
            dic: { 0: '日', 1: '一', 2: '二', 3: '三', 4: '四', 5: '五', 6: '六' },
            options: [],
            readonly: true,
            tmpEmail: '',
            html: ''
        }
    },
    methods: {
        addProjectHandler() {

            if (this.project.projectName === undefined || this.project.projectName.trim() === '') {
                ElMessage({ message: '请输入项目名称', type: 'error', })
            } else {
                let that = this
                this.axios.post(utils.baseUrl + 'reservationProject/add', this.project)
                    .then((res) => {

                        if (res.data.code === 200) {
                            that.getProjectsHandler()
                            ElMessage({ message: '服务项目添加成功', type: 'success' })
                        } else {
                            ElMessage({ message: res.data.message, type: 'error' })
                        }

                        that.project.projectName = ''
                    })
                    .catch((res) => {
                        console.log('res - ', res)
                    })
            }
        },
        getProjectsHandler: function () {
            let that = this;
            this.company = JSON.parse(localStorage.getItem('company'));
            this.axios.get(utils.baseUrl + 'reservationProject/getByCompanyId/' + this.company.companyId).then((res) => {
                that.services = res.data.data;
            })
        },
        saveCompanySettingHandler: function () {
            let that = this;
            this.company.md5 = CryptoJS.MD5(JSON.stringify(this.company)).toString()
            this.company.companyArea = this.company.companyArea.toString()
            this.axios.post(utils.baseUrl + 'reservationCompany/update', this.company).then((res) => {
                if (res.data.code === 200) {
                    that.company = res.data.data;
                    that.company.companyArea = that.company.companyArea.split(',')
                    that.company.companyArea[0] = Number(that.company.companyArea[0])
                    that.company.companyArea[1] = Number(that.company.companyArea[1])

                    that.tmpEmail = '';
                    that.readonly = true;
                    localStorage.setItem('company', JSON.stringify(that.company))
                    ElMessage({ message: '企业信息修改成功', type: 'success', })
                }
            })
        },
        delProjectHandler: function (projectId) {

            let that = this;
            ElMessageBox.confirm('确认删除当前服务信息吗?',
                'Warning',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                    center: true,
                }
            )
                .then(() => {
                    ElMessage({ type: 'success', message: 'Delete completed', })
                    this.axios.get(utils.baseUrl + 'reservationProject/del/' + projectId).then((res) => {
                        if (res.data.code === 200) {
                            ElMessage({ message: '服务项目删除成功', type: 'success', })
                            that.getProjectsHandler()
                        } else {
                            ElMessage({ message: '服务项目删除失败', type: 'error', })
                        }
                    })
                })
                .catch(() => { ElMessage({ type: 'info', message: 'Delete canceled', }) })
        },
        handleAvatarSuccess: function () {
        },
        beforeAvatarUpload: function (file) {
            let that = this;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                // e.target.result 就是Base64字符串
                console.log(e.target.result);
                that.company.companyLogo = e.target.result
                // 这里可以将Base64字符串传递给其他方法或数据属性
            };
            // 阻止默认上传行为
            return false;
        },
        onPreview: function (e) {
            this.dialogImageUrl = e.url
            this.dialogVisible = true
        },
        changeHandler: function (index) {

            if (this.weeks[index].checked) {
                this.weeks[index].weekState = 1
            } else {
                this.weeks[index].weekState = 0
            }
        },
        saveWeekHandler: function () {
            this.axios.post(utils.baseUrl + 'reservationCompanyDaySet/save', this.weeks).then((res) => {
                if (res.data.code === 200) {
                    ElMessage({ message: '日期范围设置成功', type: 'success', })
                } else {
                    ElMessage({ message: '日期范围设置失败', type: 'error', })
                }
            })
            this.company.companyArea = this.company.companyArea.toString()
            this.axios.post(utils.baseUrl + 'reservationCompany/update', this.company).then((res) => {
                if (res.data.code === 200) {
                    ElMessage({ message: '日期范围设置成功', type: 'success', })
                } else {
                    ElMessage({ message: '日期范围设置失败', type: 'error', })
                }
            })
        },
        /**
         * 获取日期设置范围
         */
        getDaySetHandler: function () {
            let that = this;
            this.axios.get(utils.baseUrl + 'reservationCompanyDaySet/get/' + this.company.companyId).then((res) => {
                if (res.data.code === 200) {

                    if (res.data.data.length != 0) {
                        that.weeks = res.data.data;
                        for (let index = 0; index < that.weeks.length; index++) {
                            const element = that.weeks[index];
                            if (element.weekState === 0) {
                                that.weeks[index].checked = false
                            } else {
                                that.weeks[index].checked = true
                            }
                            that.weeks[index].name = '周' + that.dic[index]
                        }
                    }
                } else {
                    ElMessage({ message: '日期范围设置失败', type: 'error', })
                }
            })
        },
        getArea: function (pid) {
            let that = this;
            this.axios.get(utils.baseUrl + 'reservationArea/byPId/' + pid + '/' + localStorage.getItem('lang')).then((res) => {
                if (res.data.code === 200) {
                    that.options = res.data.data;
                    if (that.company.companyArea != null) {
                        that.company.companyArea = that.company.companyArea.split(',')
                        that.company.companyArea[0] = Number(that.company.companyArea[0])
                        that.company.companyArea[1] = Number(that.company.companyArea[1])
                        // console.log(that.company)
                    }
                } else {
                    ElMessage({ message: '位置信息获取失败!', type: 'error', })
                }
            })
        },
        handleChange: function () {
            console.log('--------', this.company)
        },
        cannelEmailHandler: function () {
            this.readonly = true;
            this.company.companyEmail = this.tmpEmail;

        },
        editEmailHandler: function () {
            this.readonly = false;
            this.tmpEmail = this.company.companyEmail;
        }
    },
    created() {
        this.lan = useI18n()
        this.company = JSON.parse(localStorage.getItem('company'));
        this.project.companyId = this.company.companyId;
        this.getProjectsHandler()
        this.getDaySetHandler()
        this.getArea(0)
    }
}
</script>
<style>
.root {
    width: 100%;
}

.el-checkbox__label {
    width: 100%;
}

.avatar {
    width: 100px;
    height: 100px;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 50px;
    height: 50px;
    text-align: center;
}

code {
    font-size: 11px;
    text-align: left;
    width: 100%;
}
</style>