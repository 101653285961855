<template>
    <div style="padding: 20px;">
        <div style=" width: 100%; height: 50px; line-height: 50px; display: flex; justify-content: space-between; ">
            <div style="display: flex;" @click="backHandler">
                <el-icon style="padding-top: 17px; font-weight: bold;">
                    <ArrowLeft />
                </el-icon>
                <div style="line-height: 50px;">个人信息</div>
            </div>
            <el-image width="50px" style="width: 50px; border-radius: 50%; " :src="company.companyLogo"></el-image>
        </div>
        <div style="padding-top: 20px; width: 100%;">
            <el-form ref="elform" label-position="top" size="large" :rules="rules" :model="tmp">
                <el-form-item :label="$t('user.booking_email')">
                    <el-input style="width: 100%;" v-model="tmp.email" placeholder="Please input Email" />
                    <div style="width: 100%; font-weight: bold; text-align: left; font-size: 14px; color:#699BFF">
                        {{  $t('user.booking_sync_gmail')  }}
                    </div>
                </el-form-item>
                <el-form-item :label="$t('user.booking_user')">
                    <el-input style="width: 100%;" v-model="tmp.name" placeholder="Please input name" />
                </el-form-item>
                <el-form-item :label="$t('user.booking_commit')">
                    <el-input style="width: 100%;" type="textarea" :rows="6" v-model="tmp.commit"
                        placeholder="Please input commit" />
                </el-form-item>
            </el-form>
        </div>
        <el-button type="primary" size="large" @click="confirmHandler"
            style="background-color: #F7941A; border: none; width: 100%; margin-top: 30px; ">{{ $t('user.booking_btn') }}</el-button>
    </div>
</template>
<script>

import utils from '../../utils/Utils'
import { ElMessage } from 'element-plus'

export default {
    data() {
        return {
            companyId: 1,
            company: {},
            tmp: {
                email: '',
                name: '',
                commit: '',
            },
            rules: {
                email: [
                    {required: true},
                ]
            }
        }
    },
    methods: {
        confirmHandler: function () {

            if (this.tmp.email === '') {
                ElMessage({ type: 'error', message: '请输入邮箱信息', })
                return;
            }else if (this.tmp.name === '') {
                ElMessage({ type: 'error', message: '请输入名称!', })
                return;
            } else {
                let that = this;
                let order = JSON.parse(localStorage.getItem('order'));
                order.userEmail = this.tmp.email;
                order.userName = this.tmp.name;
                order.reservationCommit = this.tmp.commit;

                this.axios.post(utils.baseUrl + 'reservationMine/order', order).then((res) => {
                    let mineId = res.data.data;
                    localStorage.setItem('order', JSON.stringify(order))
                    that.$router.push({ name: 'result', params: { 'companyId': this.companyId, 'mineId': mineId } })
                })
            }
        },
        backHandler: function () {
            this.$router.go(-1)
        }
    },
    created() {

        this.companyId = this.$route.params.companyId; // 获取动态片段
        let that = this;
        this.axios.get(utils.baseUrl + 'reservationCompany/getByNum/' + this.companyId).then((res) => {
            that.company = res.data.data;
        })
    }
}
</script>
<style scoped>
body{
    margin: 0px;
}
</style>