import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import i18n from './i18n/index'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import VueHighlightJS from 'vue-highlightjs'
import VueQrcode from 'vue-qrcode'

const app = createApp(App).use(router)

app.use(ElementPlus, { locale: zhCn, })
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('vue-qrcode',VueQrcode)
app.use(VueHighlightJS)
app.use(i18n)
app.use(VueAxios,axios)
app.mount('#app')
