<template>
    <div style="margin: 20px; ">
        <div style=" width: 100%; height: 50px; line-height: 50px; display: flex; justify-content: space-between; ">
            <div style="line-height: 50px; font-weight: bold; ">{{ company.companyName }}</div>
            <el-image width="50px" style="width: 50px; border-radius: 50%; " :src="company.companyLogo"></el-image>
        </div>
        <!-- <div style="padding-top: 20px;">
            <div style="width: 100%; text-align: left; font-size: 14px;">
                选择地区
            </div>
            <el-select v-model="selectValue" placeholder="Select" size="large">
                <el-option v-for="item in services" :key="item.projectId" :label="item.projectName"
                    :value="item.projectId" />
            </el-select>
        </div> -->
        <div style="line-height: 50px; text-align: left;">{{ $t('user.booking_detail') }}</div>
        <div style="padding-top: 20px;">
            <div style="width: 100%; text-align: left; font-size: 14px;">
                {{ $t('user.booking_project') }}
            </div>
            <el-select v-model="selectValue" placeholder="Select" size="large" @change="selectProjectHandler">
                <el-option v-for="item in services" :key="item.projectId" :label="item.projectName"
                    :value="item.projectId">
                </el-option>
            </el-select>
        </div>
        <div style="padding-top: 20px;">
            <div style="width: 100%; text-align: left; font-size: 14px;">
                {{ $t('user.booking_date') }}
            </div>
            <!-- <div class="demo-date-picker"> -->
            <!-- <div class="block"> -->
            <!-- <el-date-picker style="width: 100%;" v-model="selectDay" value-format="YYYY-MM-DD" type="date"
                        placeholder="Pick a day" size="large" /> -->
            <vue-cal class="vue_cal" style="width: 100%; height: 90px; "
                :disableViews="['years', 'year', 'month', 'day']" :hideTitleBar="false" today-button
                :dblclickToNavigate="false" :clickToNavigate="true" :locale="language" @view-change="clickToNavigate"
                @ready="readyHandler">
                <template #weekday-heading="{ heading }">
                    <div v-if="new Date(heading.date).getTime() >= nowDate"
                        :id="'div' + new Date(heading.date).format('DD')"
                        @click="selectDayHandler('div' + new Date(heading.date).format('DD'), new Date(heading.date).format('YYYY-MM-DD'))"
                        style="width: 100%;">
                        <div>
                            {{ heading.label.indexOf('星') != -1 ? heading.label.substr(heading.label.length - 1,
                                heading.label.length) : heading.label.substr(0, 1) }}
                        </div>
                        <div>
                            {{ new Date(heading.date).format('DD') }}
                        </div>
                    </div>
                    <div v-else :id="'div' + new Date(heading.date).format('DD')"
                        style="width: 100%; background-color: #CCCCCC;">
                        <div>
                            {{ heading.label.indexOf('星') != -1 ? heading.label.substr(heading.label.length - 1,
                                heading.label.length) : heading.label.substr(0, 1) }}
                        </div>
                        <div>
                            {{ new Date(heading.date).format('DD') }}
                        </div>
                    </div>
                </template>
                <template #today-button="{}">
                    <el-button size="small" @click="todayHandler">{{ $t('user.today') }}</el-button>
                </template>
            </vue-cal>
            <!-- </div> -->
            <!-- </div> -->
        </div>
        <div style="padding-top: 20px;">
            <div
                style="width: 100%; text-align: left; font-size: 14px;display: flex; flex-direction: row; justify-content: space-between; ">
                <div>{{ $t('user.booking_time') }}<span style="color:#F7941A; font-weight: bold; ">({{ $t('user.booking_tip') }})</span></div>
            </div>
            <div id="timeDiv" v-if="times.length > 0"
                style=" width: 100%; display: flex; flex-direction: row; flex-wrap: wrap; gap: 10px; align-content: center;">
                <div style="width: 70px; line-height: 30px; height: 30px; background-color: #F0F0F0; margin-top: 10px; break-inside: avoid;cursor: pointer;"
                    v-for="(time, index) in times" :key="time" :id="'timeDiv' + index" disabled="disabled"
                    >
                    <div v-if="new Date().getTime() > new Date(this.selectDay +' ' + time.time).getTime() " style="background-color: #CCCCCC;">{{ time.time }}</div>
                    <div v-else @click="selectTimeHandler('timeDiv' + index)">{{ time.time }}</div>
                </div>
            </div>
            <div v-if="times.length === 0" style="width: 100%; text-align: center;">
                <el-empty :description="$t('user.booking_no_time')" />
            </div>
        </div>
        <div style="padding-top: 20px;">
            <div style="width: 100%; text-align: left; font-size: 14px;">
                <div>{{ $t('user.booking_people') }}({{ staffs.length }}) <span style="color:#F7941A; font-weight: bold; ">({{ $t('user.booking_people_tip') }})</span>
                </div>
            </div>
            <el-scrollbar v-if="staffs.length > 0">
                <div style="width: 100%; display: flex; ">
                    <div v-for="staff in staffs" :key="staff.staffId" style="display: flex; flex-direction: column; width: 60px; margin: 5px; 
                    padding: 5px; border-radius: 10px;" @mousedown="mouseDownHandler" :id="'staffDiv' + staff.staffId" >
                        <el-image :src="staff.staffLogo"
                            style="width: 60px; height: 60px;  border-radius: 50%;"></el-image>
                        <div style="height: 15px;"></div>
                        <div style="font-size: 14px;">{{ staff.staffName }}</div>
                    </div>
                </div>
            </el-scrollbar>
            <div v-if="staffs === null || staffs.length === 0" style="width: 100%; text-align: center; color: #687581; font-size: 13px;">
                <el-empty :description="$t('user.booking_no_people')" />
            </div>
        </div>
        <el-button type="primary" size="large" @click="confirmHandler"
            style="background-color: #F7941A; border: none; width: 100%; margin-top: 30px; ">{{  $t('user.booking_btn')  }}</el-button>
    </div>
</template>
<script>
import utils from '../../utils/Utils'
import VueCal from 'vue-cal'
import { ElMessage } from 'element-plus'

export default {
    components: { VueCal },
    data() {
        return {
            companyId: -1,
            company: {},
            services: [],
            selectValue: '',
            selectDay: '',
            staffs: [],
            times: [],
            selectTime: '',
            selectStaffId: '',
            currentElementId: '',
            currentStaffId: '',
            selectId: '',
            nowDate: '',
            language: 'en',
        }
    },
    methods: {
        getServices: function () {
            let that = this;
            this.axios.get(utils.baseUrl + 'reservationProject/getByCompanyIdNum/' + this.companyId).then((res) => {
                that.services = res.data.data;
            })
        },
        getStaff: function () {
            let that = this;
            this.axios.get(utils.baseUrl + 'reservationProject/getByCompanyIdNum/' + this.companyId).then((res) => {
                that.services = res.data.data;
            })
        },
        getTimes: function () {
            let that = this;
            this.axios.get(utils.baseUrl + 'reservationCompany/timesByNum/' + this.companyId + '/' + this.selectDay).then((res) => {
                that.times = res.data.data;
            })
        },
        selectProjectHandler: function () {

            // 清空接洽人选择
            if (this.currentStaffId != '' && document.getElementById(this.currentStaffId) != null) {
                document.getElementById(this.currentStaffId).style.backgroundColor = 'white';
            }
            // 加载当前服务的员工
            let that = this;
            this.axios.get(utils.baseUrl + 'reservationCompanyStaff/getStaffBy/' + this.companyId + '/' + this.selectValue).then((res) => {
                if (res.data.code === 200) {
                    that.staffs = res.data.data;
                } else {
                    ElMessage({ type: 'error', message: res.data.message, })
                }
            })
        },
        mouseDownHandler: function (e) {

            // console.log(this.currentStaffId)
            if (this.currentStaffId != '' && document.getElementById(this.currentStaffId) != null) {
                document.getElementById(this.currentStaffId).style.backgroundColor = 'white';
            }
            this.currentStaffId = e.currentTarget.id;
            document.getElementById(this.currentStaffId).style.backgroundColor = '#F7941A';
            this.selectStaffId = Number(this.currentStaffId.substring(8, this.currentStaffId.length))
            console.log('this.selectStaffId - ',this.selectStaffId)
        },
        /**
         * 点击确认进行下一步
         */
        confirmHandler: function () {

            if (this.selectValue === '') {
                ElMessage({ type: 'error', message: '请选择服务项目!', })
            } else if (this.selectStaffId === '') {
                ElMessage({ type: 'error', message: '请选择预约的员工!', })
            } else if (this.selectTime === '') {
                ElMessage({ type: 'error', message: '请选择预约的时间段!', })
            } else {
                let order = {
                    companyId: this.company.companyId,
                    projectId: this.selectValue,
                    staffId: this.selectStaffId,
                    reservationStaffTime: this.selectTime,
                    reservationDatetime: this.selectDay,
                    reservationState: 0
                }
                localStorage.setItem('order', JSON.stringify(order));
                this.$router.push({ name: 'info', params: { 'companyId': this.companyId } })
            }
        },
        /**
         * 选择时间段
         */
        selectTimeHandler: function (divId) {

            if (this.currentElementId != '') {
                document.getElementById(this.currentElementId).style.backgroundColor = '#F0F0F0';
                document.getElementById(this.currentElementId).style.color = '#000000';
            }
            this.currentElementId = divId;
            document.getElementById(this.currentElementId).style.backgroundColor = '#F7941A';
            document.getElementById(this.currentElementId).style.color = '#FFFFFF';
            this.selectTime = document.getElementById(this.currentElementId).textContent;

            console.log(document.getElementById(this.currentElementId).textContent)
        },
        selectDayHandler: function (id, day) {

            let today = new Date();
            today.setHours(0, 0, 0, 0);

            if (new Date(day).getTime() < today.getTime()) {
                ElMessage({ type: 'error', message: '当前时间不可预约!', })
            } else {
                this.selectDay = day;
                this.times = [];
                this.getTimes()
                if (this.selectId != '' && document.getElementById(this.selectId) != null) {
                    document.getElementById(this.selectId).style.backgroundColor = 'white';
                    document.getElementById(this.selectId).style.color = 'black';
                }
                if (document.getElementById(id) != null) {
                    document.getElementById(id).style.backgroundColor = '#F7941A';
                    document.getElementById(id).style.color = 'white';
                }
                this.selectId = id;
            }
        },
        todayHandler: function () {
            this.selectDay = new Date().format('YYYY-MM-DD')

            if (this.selectId != '') {
                document.getElementById(this.selectId).style.backgroundColor = 'white';
                document.getElementById(this.selectId).style.color = 'black';
            }

            document.getElementById('div' + new Date().format('DD')).style.backgroundColor = '#F7941A';
            document.getElementById('div' + new Date().format('DD')).style.color = 'white';
            this.selectId = 'div' + new Date().format('DD');
            this.getTimes()
        },
        /**
         * 视图切换
         */
        clickToNavigate: function (obj) {
            this.selectDay = new Date(obj.startDate).format('YYYY-MM-DD');
            // console.log('this.nowDate - ',this.nowDate)
            // 如果当前时间大于视图的第一个时间则默认选择当前时间
            if (this.nowDate > new Date(obj.startDate).getTime()) {
                this.autoHandler(this.nowDate, obj.endDate, 1)
            }else{
                this.autoHandler(obj.startDate, obj.endDate, 1)
            }
        },
        readyHandler: function (obj) {
            this.selectDay = new Date().format('YYYY-MM-DD');
            this.autoHandler(obj.startDate, obj.endDate, 2)
        },
        autoHandler: function (startDate, endDate, type) {

            console.log('endDate - ', endDate)
            setTimeout(() => {
                this.selectId = type === 1 ? 'div' + new Date(startDate).format('DD') : 'div' + new Date().format('DD');
                document.getElementById(this.selectId).style.backgroundColor = '#F7941A';
                document.getElementById(this.selectId).style.color = 'white';
            }, 100)
            this.times = [];
            this.getTimes();
            // this.changeHandler()
        },
    },
    created() {
        this.companyId = this.$route.params.companyId;
        this.selectDay = new Date().format('YYYY-MM-DD')
        this.nowDate = new Date().setHours(0, 0, 0, 0);
        console.log('new Date() - ',new Date().getTime())
        let that = this;
        this.axios.get(utils.baseUrl + 'reservationCompany/getByNum/' + this.companyId).then((res) => {
            that.company = res.data.data;
        })
        this.getServices()
    },
    mounted() {
        document.getElementById('div' + new Date().format('DD')).style.backgroundColor = '#F7941A';
        document.getElementById('div' + new Date().format('DD')).style.color = 'white';
        this.selectId = 'div' + new Date().format('DD');

        this.getTimes()

        this.langage = localStorage.getItem('lang') === 'zh' ? 'zh-cn' : localStorage.getItem('lang');

    }
}
</script>
<style scoped>
body {
    margin: 0px;
}

.demo-date-picker {
    width: 100%;
}

.block {
    width: 100%;
}

.vue_cal .vuecal__title {
    font-size: 10px;
}
.vue_cal .el-button .el-button--small span{
    margin-top: 3px !important;
}
</style>