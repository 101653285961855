<template>
    <div style="width: 100%; height: 100%; display: flex; justify-content: center; padding-top: 150px; ">
        <!-- 第一步-->
        <el-form :model="company" size="large" label-width="80px" v-if="step === 1"
            style="width: 300px; padding:15px; border: 1px solid #F7941A; " label-position="top">
            <el-form-item>
                <div style="border-bottom: 1px dashed #F7941A; width: 100%; text-align: left ">
                    <div style="color: #F7941A; font-size: 20px; font-weight: bold;">{{ $t('login.loginTip') }}</div>
                    <div
                        style="color: #F7941A; font-size: 25px; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-weight: bold;">
                        Booking1s</div>
                </div>
            </el-form-item>
            <el-form-item :label="$t('login.userName')">
                <el-input v-model="company.companyEmail" />
            </el-form-item>
            <el-form-item :label="$t('login.password')">
                <el-input v-model="company.companyPwd" type="password" show-password />
            </el-form-item>
            <el-form-item :label="$t('login.pwdConfim')" v-if="isLogin === false">
                <el-input v-model="company.conPwd" type="password" show-password />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" style="width: 100%; height: 40px; background-color: #F7941A; border: none;"
                    @click="onNextToTwo" :loading="isLoading" v-if="isLogin === false">Next</el-button>
                <el-button type="primary" v-if="isLogin === true" :loading="isLoading"
                    style="width: 100%; height: 40px; background-color: #F7941A; border: none;" @click="onSubmit">{{
                        $t('login.login') }}</el-button>
            </el-form-item>
            <el-form-item>
                <div style="text-align: right; width: 100%; display: flex; justify-content: end; ">
                    <el-button @click="registerHandler" v-if="isLogin === true">{{ $t('login.register') }}</el-button>
                    <el-button @click="toLoginHandler" v-if="isLogin === false">{{ $t('login.alLogin') }}</el-button>
                </div>
            </el-form-item>
            <el-form-item>
                <div style="color: #2A6DFF; vertical-align: text-bottom; display: flex; ">
                    <el-link @click="openPage('privacy.html')">{{ $t('company.yinsi') }}</el-link>
                    <div style="width: 20px;"></div>
                    <el-link @click="openPage('service.html')">{{ $t('company.service') }}</el-link>
                </div>
            </el-form-item>
        </el-form>
        <!--第二步-->
        <el-form v-if="step === 2" style="width: 300px; padding:15px; border: 1px solid #F7941A; " label-position="top">
            <el-form-item>
                <div style="border-bottom: 1px dashed #F7941A; width: 100%; text-align: left ">
                    <div style="color: #F7941A; font-size: 25px; font-weight: bold;">输入验证码</div>
                    <div
                        style="color: #000000; font-size: 16px; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-weight: bold;">
                        请检查您的邮箱</div>
                </div>
            </el-form-item>
            <el-form-item>
                <div>
                    我们发送了一封包括验证码的邮箱到以下邮箱
                </div>
                <div style="color: #F7941A; width: 100%; text-align: center ">
                    {{ company.companyEmail }}
                </div>
            </el-form-item>
            <el-form-item>
                <div style="display: flex; justify-content: space-around; width: 100%; ">
                    <el-input id="fir" class="div_class" maxlength="1" v-model="f" @input="change1Handler" />
                    <el-input id="sec" class="div_class" maxlength="1" v-model="s" @input="change2Handler" />
                    <el-input id="thr" class="div_class" maxlength="1" v-model="t" @input="change3Handler" />
                    <el-input id="for" class="div_class" maxlength="1" v-model="fs" @input="change4Handler" />
                </div>
            </el-form-item>
            <el-form-item>
                <div>
                    Enter the code in the email to creatingyour account. The code will expire in 15min.
                </div>
            </el-form-item>
            <el-form-item>
                <div style="text-align: right; width: 100%; padding-top: 60px">
                    <el-button type="primary"
                        style="width: 100%; height: 40px; background-color: #F7941A; border: none;"
                        @click="onNextToThree">Next</el-button>
                </div>
            </el-form-item>
        </el-form>
        <el-form v-if="step === 3" style="width: 300px; padding:15px; border: 1px solid #F7941A; " label-position="top">
            <el-form-item>
                <div style=" width: 100%; text-align: center; padding-top: 50px; ">
                    <div style="color: #F7941A; font-size: 25px; font-weight: bold;">恭喜您</div>
                </div>
            </el-form-item>
            <el-form-item>
                <div style="color: #F7941A; width: 100%; text-align: center ">
                    <img style="width: 100px; height: 100px; " src="../assets/complete.png" />
                </div>
            </el-form-item>
            <el-form-item>
                <div style="text-align: right; width: 100%; padding-top: 100px">
                    <el-button type="primary"
                        style="width: 100%; height: 40px; background-color: #F7941A; border: none;"
                        @click="beginHandler">马上开始</el-button>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import utils from '../utils/Utils'
import { ElMessage } from 'element-plus'
import CryptoJS from 'crypto-js'
// import i18n from '@/i18n/i18n';
import { useI18n } from 'vue-i18n'
// const { locale } = useI18n();
export default {
    data() {
        return {
            company: {
                companyEmail: ''
            },
            isLogin: true,
            step: 1,
            isLoading: false,
            f: '',
            s: '',
            t: '',
            fs: '',
            lan: null
        }
    },
    methods: {
        registerHandler: function () {
            this.isLogin = false
        },
        toLoginHandler: function () {
            this.isLogin = true
        },
        onNextToTwo: function () {

            this.isLoading = true;
            // 对两个密码进行校验
            if (this.company.companyPwd != this.company.conPwd) {
                ElMessage({ showClose: true, message: '两次输入的密码不一致.', type: 'error', })
                return;
            }

            // 发送电子邮件
            this.axios.post(utils.baseUrl + 'reservationCompany/sendEmail', this.company).then((res) => {
                if (res.data.code === 500) {
                    ElMessage({ showClose: true, message: '当前邮箱已经被使用.', type: 'error', })
                } else {
                    ElMessage({ showClose: true, message: '验证邮件已发送，请登录邮箱查看.', type: 'success', })
                    this.step = 2;
                }
                this.isLoading = false;
            }).catch(() => {
                this.isLoading = false;
            })
        },
        onNextToThree: function () {
            // 注册
            this.isLoading = true;
            let that = this;
            this.company.companyRandom = this.f + this.s + this.t + this.fs;
            this.company.companyArea = '';
            this.company.companyLogo = utils.baseUrl + 'logo.png';
            this.axios.post(utils.baseUrl + 'reservationCompany/register', this.company).then((res) => {

                if (res.data.code === 200) {
                    this.step = 3;
                    that.company = res.data.data;
                    that.isLoading = false;
                } else {
                    ElMessage({ showClose: true, message: res.data.message, type: 'error', })
                }
            })
        },
        beginHandler: function () {
            this.$router.push('/home/' + this.company.companyId + '/' + this.company.companyIdnum)
        },
        change1Handler: function () {
            console.log('--------------')
            document.getElementById('sec').focus();
        },
        change2Handler: function () {
            document.getElementById('thr').focus();
        },
        change3Handler: function () {
            document.getElementById('for').focus();
        },
        change4Handler: function () {
            // document.getElementById('fir').focus();
        },
        onSubmit: function () {
            this.isLoading = true;
            let that = this;
            this.company.md5 = CryptoJS.MD5(JSON.stringify(this.company)).toString()
            this.axios.post(utils.baseUrl + 'reservationCompany/login', this.company).then((res) => {

                that.isLoading = false;
                if (res.data.code === 500) {
                    ElMessage({ showClose: true, message: res.data.message, type: 'error', })
                } else {
                    let companyId = res.data.data.companyId;
                    let num = res.data.data.companyIdnum;
                    this.$router.push({ name: 'home', params: { 'companyId': companyId, 'num': num } })
                }
            }).catch((res) => {
                ElMessage({ showClose: true, message: '登录失败' + res, type: 'error', })
            })
        },
        openPage: function (url) {
            window.open(utils.staUrl + url, "_blank");
        }
    },
    created() {
        this.lan = useI18n()
    }
}
</script>
<style scoped>
body {
    margin: 0px;
}

.div_class {
    width: 35px;
    height: 35px;
    /* border: 1px solid #A8A8A8; */
    text-align: center;
    font-size: 20px;
}
</style>