<template>
    <div style="padding: 0px;">
        <!-- header -->
        <div
            style="height: 90px; background-color:#444B50; display: flex; justify-content: space-between; padding-top: 30px; padding-left: 20px; padding-right: 20px;">
            <img v-if="staff.staffLogo != null" style="width: 60px; height: 60px; border-radius: 50%; "
                :src="staff.staffLogo" />
            <img v-else style="width: 60px; height: 60px;" src="../../assets/staff_headpic.png" />
            <div style="display: flex; ">
                <div style="background-color: #E7E7E7; width: 40px; height: 40px; border-radius: 50%; ">
                    <el-icon size="30" style="color: white; margin-top: 5px;">
                        <Setting color="#444B50" @click="settHandler" />
                    </el-icon>
                </div>
                <div style="width: 10px;"></div>
                <div style="background-color: #E7E7E7; width: 40px; height: 40px; border-radius: 50%; ">
                    <img src="../../assets/out.png" style="width: 30px; margin-top: 5px;" @click="logoutHandler" />
                </div>
            </div>
        </div>
        <div style="display: flex; flex-direction: column; padding-top: 20px; width: 100%; ">
            <div style="width: 100%; justify-content: center; display: flex;">
                <div
                    style="width: 90%; height: 30px; line-height: 30px; background-color: #727272; border-radius: 10px; color: white; ">
                    {{ $t('staff.today_tip') }} {{ todayCount }} /{{ $t('staff.week_tip') }} {{ weekTotal }} 
                </div>
            </div>
            <div style="padding-top: 10px;">
                <!-- 顶部标识点，有记录的显示 -->
                <div
                    style="width:100%; height: 10px; display: flex; justify-content:space-around ; position: relative; top: 50px; ">
                    <div v-for="day in loadDay" :key="day" style="width: 5px; height: 5px;  ">
                        <div v-if="day.value != undefined" style="width: 5px; height: 5px; background-color: #F7941A;">
                        </div>
                    </div>
                </div>
                <vue-cal class="vue_cal" style="width: 100%; height: 90px; "
                    :disableViews="['years', 'year', 'month', 'day']" :hideTitleBar="false" today-button
                    :dblclickToNavigate="false" v-model:selected-date="selectDay" :clickToNavigate="false"
                    :locale="language" @view-change="clickToNavigate" @ready="readyHandler">
                    <template #weekday-heading="{ heading }">
                        <div :id="'div' + new Date(heading.date).format('DD')"
                            @click="selectDayHandler('div' + new Date(heading.date).format('DD'), new Date(heading.date).format('YYYY-MM-DD'))"
                            style="width: 200%;">
                            <div>
                                {{ heading.label.indexOf('星') != -1 ? heading.label.substr(heading.label.length - 1,
                                    heading.label.length) : heading.label.substr(0, 1) }}
                            </div>
                            <div>
                                {{ new Date(heading.date).format('DD') }}
                            </div>
                        </div>
                    </template>
                    <template #today-button="{}">
                        <el-button size="small" @click="todayHandler">{{ $t('staff.today') }}</el-button>
                    </template>
                </vue-cal>
            </div>
        </div>
        <div>
            <div style="padding-top: 20px;border-bottom: 1px solid #E5E5E5;">
                <div v-if="orders.length == 0">
                    <el-empty :image-size="200" description="暂无预约记录" />
                </div>
                <div v-for="order in orders" :key="order.reservationId"
                    style="display: flex; flex-direction: row; border-top: 1px solid #E5E5E5;">
                    <div
                        style="width: 90px; background-color: #FFFFFF; font-weight: bold; padding-top: 10px; border-right: 2px solid #FCCA8E; ">
                        {{ order.reservationStaffTime }}
                    </div>
                    <div style="background-color: #F9F9F9; width: 100%; font-size: 13px;padding-top: 10px;">
                        <div style="display: flex; flex-direction: row;">
                            <div
                                style="margin: 5px; width: 10px; height: 10px; border-radius: 50%; background-color: #F7941A;">
                            </div>
                            <div
                                style="margin-left: 11px; display: flex;  padding-right: 10px; justify-content: space-between; ">
                                <div>{{ order.projectName }}</div>
                                <!-- <el-icon style="margin-top: 1px; position:fixed; right: 20px;" size="17">
                                    <Message @click="messageCommit(order)" />
                                </el-icon> -->
                                <img style="width: 13px; height: 13px; margin-top: 5px; position:fixed; right: 20px;"
                                    src="../../assets/icon_notice_21x21.png" @click="messageCommit(order)" />
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row;">
                            <el-icon style="margin: 4px; ">
                                <Warning />
                            </el-icon>
                            <div>
                                <div style="margin-left: 10px; text-align: left;">{{ order.userName }}</div>
                                <div style="margin-left: 10px; color: #2697F8; text-align: left;">{{ order.userEmail }}
                                </div>
                                <div style="margin-left: 10px; text-align: left;">{{ order.reservationCommit }}</div>
                            </div>
                        </div>
                        <div style="height: 10px;"></div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog v-model="visbile" :title="$t('staff.commit_title')" width="90%">
            <el-input v-model="commit" type="textarea" style="width: 90%; height: 100px;" row="5" size="large"
                maxlength="50" :placeholder="$t('staff.commit_desc')"></el-input>
            <span class="dialog-footer">
                <el-button @click="visbile = false">Cancel</el-button>
                <el-button type="primary" @click="addMessageHandler">Confirm</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import VueCal from 'vue-cal'
import utils from '../../utils/Utils'
import { ElMessage } from 'element-plus'

export default {
    components: { VueCal },
    data() {
        return {
            staff: {},
            orders: [],
            selectDay: '',
            visbile: false,
            commit: '',
            selectOrder: {},
            todayCount: 0,
            selectId: '',
            loadDay: [],
            weekTotal: 0,
            language: 'en',

        }
    },
    methods: {
        settHandler: function () {
            this.$router.push({ name: 'staffSet' })
        },
        messageCommit: function (order) {
            this.visbile = true;
            this.selectOrder = order;
        },
        addMessageHandler: function () {
            let that = this;
            this.selectOrder.staffCommit = this.commit;
            this.axios.post(utils.baseUrl + 'reservationMine/update', this.selectOrder).then((res) => {
                if (res.data.code === 200) {
                    ElMessage({ type: 'success', message: '备忘添加成功', })
                    that.visbile = false;
                }
            })
        },
        changeHandler: function () {
            let that = this;
            let companyId = this.$route.params.companyId;
            this.staff = JSON.parse(localStorage.getItem('staff'))
            this.axios.get(utils.baseUrl + 'reservationMine/list/' + companyId + '/' + this.selectDay + '/' + this.staff.staffId).then((res) => {
                if (res.data.code === 200) {
                    that.orders = res.data.data;

                    // console.log(that.selectDay,new Date().format('YYYY-MM-DD'))
                    if (that.selectDay.format('YYYY-MM-DD') === new Date().format('YYYY-MM-DD')) {
                        that.todayCount = that.orders.length
                    }
                }
            })
        },
        logoutHandler: function () {
            this.$router.push({ name: 'staffLogin' })
        },
        selectDayHandler: function (id, day) {
            this.selectDay = day;
            this.changeHandler()

            if (this.selectId != '' && document.getElementById(this.selectId) != null) {
                document.getElementById(this.selectId).style.backgroundColor = 'white';
                document.getElementById(this.selectId).style.color = 'black';
            }
            document.getElementById(id).style.backgroundColor = '#F7941A';
            document.getElementById(id).style.color = 'white';
            this.selectId = id;
        },
        todayHandler: function () {
            this.selectDay = new Date().format('YYYY-MM-DD')

            if (this.selectId != '' && document.getElementById(this.selectId) != null) {
                document.getElementById(this.selectId).style.backgroundColor = 'white';
                document.getElementById(this.selectId).style.color = 'black';
            }
            if (document.getElementById(this.selectId) != null) {
                document.getElementById('div' + new Date().format('DD')).style.backgroundColor = '#F7941A';
                document.getElementById('div' + new Date().format('DD')).style.color = 'white';
                this.selectId = 'div' + new Date().format('DD');
                this.changeHandler()
            }
        },
        clickToNavigate: function (obj) {
            this.autoHandler(obj.startDate, obj.endDate, 1)
        },
        readyHandler: function (obj) {
            this.selectDay = new Date().format('YYYY-MM-DD');
            this.autoHandler(obj.startDate, obj.endDate, 2)
        },
        autoHandler: function (startDate, endDate, type) {

            this.loadDay = []
            let start = new Date(startDate).getTime();
            let end = new Date(endDate).getTime();

            while (start < end) {
                this.loadDay.push({ 'day': new Date(start).format('YYYY-MM-DD'), 'value': 0 })
                start = start + 1 * 24 * 60 * 60 * 1000;
            }
            setTimeout(() => {
                this.selectId = type === 1 ? 'div' + new Date(startDate).format('DD') : 'div' + new Date().format('DD');
                document.getElementById(this.selectId).style.backgroundColor = '#F7941A';
                document.getElementById(this.selectId).style.color = 'white';
            }, 100)
            // console.log('day - ', this.loadDay)

            this.changeHandler()
            this.getCountByDate(new Date(startDate).format('YYYY-MM-DD'), new Date(endDate).format('YYYY-MM-DD'));
        },
        getCountByDate: function (start, end) {
            this.staff = JSON.parse(localStorage.getItem('staff'));
            let that = this;
            return new Promise((resove, reject) => {
                that.axios.get(utils.baseUrl + 'reservationCompanyStaff/count/' + start + '/' + end + '/' + this.staff.staffId)
                    .then((res) => {
                        resove(res.data.data)
                        that.weekTotal = res.data.data.total;
                        // that.loadDay = res.data.data.map;
                        for (let index = 0; index < that.loadDay.length; index++) {
                            that.loadDay[index]['value'] = res.data.data.map[that.loadDay[index]['day']]
                        }
                        console.log(that.loadDay)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        // getEvents: function () {
        //     let that = this;
        //     let companyId = this.$route.params.companyId;
        //     this.axios.get(utils.baseUrl + 'reservationMine/list/' + companyId + '/' + this.selectDay + '/' + this.staff.staffId).then((res) => {
        //         if (res.data.code === 200) {
        //             that.orders = res.data.data;
        //             that.todayCount = that.orders.length
        //         }
        //     })
        // }
    },
    created() {
        this.selectDay = new Date().format('YYYY-MM-DD');
    },
    mounted(){
        this.staff = JSON.parse(localStorage.getItem('staff'))
        this.langage = localStorage.getItem('lang') === 'zh' ? 'zh-cn' : localStorage.getItem('lang');

        // this.getEvents()
    }
}
</script>
<style>
body {
    margin: 0px;
}

.vue_cal .vuecal__flex {
    /* height: 40px; */
}

.vue_cal .vuecal__title {
    font-size: 10px;
}

.vue_cal .vuecal__title-bar {
    font-size: 15px;
    height: 45px;
    line-height: 45px;
    background-color: white;
}

.vue_cal .vuecal__view-btn {
    display: none;
}

.vue_cal .vuecal__weekdays-headings {
    padding-left: 0px !important;
}
</style>