<template>
    <div>
        <!-- header -->
        <div
            style="height: 80px; background-color:white; display: flex; justify-content: space-between; padding-top: 30px; padding-left: 20px; padding-right: 20px;">
            <div style="line-height: 60px;">
                {{ company.companyName }}
            </div>
            <el-image style="width: 60px; height: 60px; border-radius: 50%; " :src="company.companyLogo"></el-image>
        </div>
        <div id="div" style="background-color: #444B50; padding-top: 100px; display: flex; justify-content: center; ">
            <el-form :model="staff" size="large" label-width="80px" style="width: 80%; color: white;"
                label-position="top">
                <el-form-item class="custom-label-color" :label="$t('staff.email')">
                    <el-input v-model="staff.staffEmail" />
                </el-form-item>
                <el-form-item class="custom-label-color" :label="$t('staff.password')">
                    <el-input v-model="staff.staffPassWord" type="password" show-password />
                </el-form-item>
                <el-form-item>
                    <div style="text-align: right; width: 100%; padding-top: 30px;">
                        <el-button style="background-color: #F7941A; border: none; width: 100%; color: white; "
                            @click="loginHandler">{{ $t('staff.login_btn') }}</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import utils from '../../utils/Utils'
import { ElMessage} from 'element-plus'

export default {
    data() {
        return {
            company: {},
            staff: {},
        }
    },
    methods: {
        loginHandler: function () {
            let that = this;
            this.staff.companyId = this.$route.params.companyId;
            this.axios.post(utils.baseUrl + 'reservationCompanyStaff/login', this.staff).then((res) => {
                if (res.data.code === 200) {
                    localStorage.setItem('staff', JSON.stringify(res.data.data))
                    that.$router.push({ name: 'staff', params: { 'companyId': that.company.companyId } })
                }else{
                    ElMessage({ type: 'error', message: '用户名或密码错误!', })
                }
            })
        }
    },
    created() {

        let that = this;
        let companyId = this.$route.params.companyId;
        this.axios.get(utils.baseUrl + 'reservationCompany/get/' + companyId).then((res) => {
            if (res.data.code === 200) {
                that.company = res.data.data;
            }
        })
    },
    mounted(){
        document.getElementById('div').style.height = (window.innerHeight - 230) + 'px';
        console.log(window.innerHeight)
    }
}
</script>
<style>
body{
    margin: 0px;
}
.custom-label-color .el-form-item__label {
    color: white;
    /* 红色字体 */
    font-weight: bold;
}
</style>